<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.fullName)"
          :variant="`light-${resolveUserRoleVariant(userData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ info.fullName }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.fileinput.$el.childNodes[0].click()"
        >
          <b-form-file
            v-model="fileavatar"
            ref="fileinput"
            accept="image/png, image/jpeg"
            class="d-none"
            @input="addAvatar"
          >
          </b-form-file>
          <!-- <input
            ref="refInputEl"
            type="file"
            class="d-none"
            accept="image/png, image/jpeg"
            @input="inputImageRenderer2()"
          /> -->
          <span class="d-none d-sm-inline">更新</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <b-button
          variant="outline-secondary"
          @click="handleRemoveAvatar"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">削除</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <ValidationObserver ref="formprofile">
      <b-form>
        <b-row>
          <!-- Field: Email -->
          <b-col cols="12" md="4">
            <b-form-group label="メールアドレス" label-for="email">
              <b-form-input
                id="email"
                v-model="info.email"
                disabled
                type="email"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Full Name -->
          <b-col cols="12" md="4">
            <b-form-group label="氏名" label-for="full-name">
              <b-form-input id="full-name" v-model="info.fullName" />
            </b-form-group>
          </b-col>

          <!-- Field: Phone -->
          <b-col cols="12" md="4">
            <b-form-group label="電話番号:" label-for="phone">
              <b-form-input id="phone" v-model="info.phone" />
            </b-form-group>
          </b-col>

          <!-- Field: Company -->
          <b-col cols="12" md="4">
            <b-form-group label="会社名:" label-for="company">
              <b-form-input id="company" v-model="info.company" />
            </b-form-group>
          </b-col>

          <!-- Field: Address -->
          <b-col cols="12" md="4">
            <b-form-group label="住所:" label-for="address">
              <b-form-input id="address" v-model="info.address" />
            </b-form-group>
          </b-col>

          <!-- Field: Position -->
          <b-col cols="12" md="4">
            <b-form-group label="役職:" label-for="position">
              <b-form-input id="position" v-model="info.position" />
            </b-form-group>
          </b-col>

          <!-- old password -->
          <b-col cols="12" md="4">
            <b-form-group
              label="既存パスワード入力"
              label-for="account-old-password"
            >
              <ValidationProvider
                name="old-password"
                rules="passwordlength"
                #default="{ errors }"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    placeholder="既存パスワード入力"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <span class="input-error">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <!--/ old password -->

          <!-- new password -->
          <b-col cols="12" md="4">
            <b-form-group
              label-for="account-new-password"
              label="新しいパスワード入力"
            >
              <ValidationProvider
                name="new-password"
                rules="passwordlength"
                #default="{ errors }"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="新しいパスワード入力"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <span class="input-error">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col cols="12" md="4">
            <b-form-group
              label-for="account-retype-new-password"
              label="新しいパスワード再入力"
            >
              <ValidationProvider
                name="new-re-password"
                rules="passwordlength"
                #default="{ errors }"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="新しいパスワード再入力"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <span class="input-error">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->
        </b-row>
      </b-form>
    </ValidationObserver>
    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="handleSaveChange"
    >
      更新されました。
    </b-button>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
extend("passwordlength", (value) => {
  if (value.length >= 8) return true;

  return "パスワードは8文字以上である必要があります。";
});
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BFormFile,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import useUsersList from "../users-list/useUsersList";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
export default {
  data() {
    return {
      info: null,
      fileavatar: null,
      passwordValueOld: "",
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BFormFile,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  async created() {
    await this.getData();
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList();

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    const permissionsData = [
      {
        module: "Admin",
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: "Staff",
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: "Author",
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: "Contributor",
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: "User",
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ];

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      async (base64) => {
        // eslint-disable-next-line no-param-reassign
        try {
          let payload = { avatar: base64 };
          let res = await axios.put("CustomUser/add-avatar-to-user", payload);
          if (res.status == 200) {
            props.userData.avatar = base64;
            // this.$toast({
            //   component: ToastificationContent,
            //   position: "top-right",
            //   props: {
            //     title: "成功",
            //     text: `更新が完了しました。`,
            //     variant: "success",
            //   },
            // });
            // this.info.avatar = base64;
          }
        } catch (e) {
          alert(e.response.data.message);
          if (e.response.data.message) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Failed",
                text: e.response.data.message,
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Failed",
                text: "Update Avatar Failed",
                variant: "danger",
              },
            });
          }
        }

        // props.userData.avatar = base64;
      }
    );

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  methods: {
    async addAvatar() {
      if (this.$refs.fileinput.files.length == 1) {
        try {
          var bodyFormData = new FormData();
          bodyFormData.append("avatar", this.$refs.fileinput.files[0]);
          let res = await axios({
            url: `CustomUser/add-avatar-to-user`,
            method: "PUT",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
          });
          if (res.data.succeeded) {
                this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "成功",
                text: `更新が完了しました。`,
                variant: "success",
              },
            });
            this.userData.avatar = res.data.data.avatar;
          } else {

          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Failed",
              text: error.response.data.message,
              variant: "danger",
            },
          });
        }
      }
    },
    async handleRemoveAvatar() {
      try {
        let res = await axios.delete("CustomUser/remove-avatar");
        if (res.status == 200) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "成功",
              text: `削除が完了しました。`,
              variant: "success",
            },
          });
          this.userData.avatar = "";
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "失敗しました。",
            text: "削除が失敗しました。",
            variant: "danger",
          },
        });
      }
    },
    async getData() {
      let res = await axios.get("CustomUser/user-info");
      this.info = res.data.data;
      this.userData.avatar = this.info.avatar;
    },
    async validate() {
      let isFormValid = await this.$refs.formprofile.validate();
      if (!isFormValid) {
        return false;
      }

      if (this.passwordValueOld) {
        if (this.newPasswordValue == false || this.RetypePassword == false) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Warning",
              text: "NewPassword and Retype Password can't be empty",
              variant: "danger",
            },
          });
          return false;
        }
        if (this.newPasswordValue != this.RetypePassword) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Warning",
              text: "入力された新しいパスワードは異なっています。",
              variant: "danger",
            },
          });
          return false;
        }
        return true;
      }
      return true;
    },
    async handleSaveChange() {
      let checkPassWord = await this.validate();
      if (checkPassWord) {
        let payload = {
          fullName: this.info.fullName,
          phone: this.info.phone,
          address: this.info.address,
          company: this.info.company,
          position: this.info.position,
        };
        if (this.passwordValueOld) {
          payload = {
            ...payload,
            oldPassword: this.passwordValueOld,
            newPassword: this.RetypePassword,
          };
        }
        try {
          let res = await axios.put("CustomUser/user-info", payload);
          console.log(res);
          if (res.status == 200) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "成功",
                text: `Update Profile Success`,
                variant: "success",
              },
            });
            this.passwordValueOld = "";
            this.RetypePassword = "";
            this.passwordValueOld = "";
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "更新が失敗しました。",
              text: e.response.data.message,
              variant: "danger",
            },
          });
        }
      }
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.input-error {
  color: #f22435;
  display: block;
  margin-top: 5px;
  margin-left: 2px;
}
</style>
